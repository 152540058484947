import { render, staticRenderFns } from "./overview-commentary-card.vue?vue&type=template&id=15c8e91b&scoped=true"
import script from "./overview-commentary-card.vue?vue&type=script&lang=js"
export * from "./overview-commentary-card.vue?vue&type=script&lang=js"
import style0 from "./overview-commentary-card.vue?vue&type=style&index=0&id=15c8e91b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15c8e91b",
  null
  
)

export default component.exports