export default {
    graphURL: 'https://securepayementsit.standardbank.co.za/api/graphql',
    sessionTimeout: 600000,
    contactUsFormURL: 'https://6nnije1zwc.execute-api.af-south-1.amazonaws.com/dev',
    authServer: 'https://enterprisestssit.standardbank.co.za',
    authServerLogout: 'https://enterprisestssit.standardbank.co.za/idp/startSLO.ping',
    oauthClientID: 'f3a22c30-6d91-4788-87d6-58ed5b9c668c',
    appDomain: 'https://securepayementsit.standardbank.co.za',
    ecentricEndpoint: '',
    pingProfilePage: 'https://enterprisestssit.standardbank.co.za/pf/idprofile.ping?LocalIdentityProfileID=LyJGLOCavNTpFzPg&userType=general',
}
